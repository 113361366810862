import Vue from 'vue'
import Vuex from 'vuex'

import { settings } from './settings.module'
import { behavior } from './behavior.module'
import { alert } from './alert.module'
import { user } from './user.module'
import { ad } from './ad.module'
import { pub } from './pub.module'
import { template } from './template.module'
import { doc } from './doc.module'
import { file } from './file.module'
import { place } from './place.module'

// Pub Connectors
import { cbo } from './connector/cbo.module'
import { extension } from './connector/extension.module'

// Micro Services
import { ofs } from './micro/ofs.module'

Vue.use(Vuex)

export const store = new Vuex.Store({
    modules: {
        settings,
        behavior,
        alert,
        user,
        ad,
        pub,
        template,
        doc,
        file,
        place,
        cbo,
        extension,
        ofs,
    },
    strict: process.env.NODE_ENV !== 'production',
})
