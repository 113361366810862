import Vue from 'vue'

const PLACES = process.env.VUE_APP_API_URL_PLACES
const PLACES_PMS = process.env.VUE_APP_API_URL_PLACES_PMS
const PLACES_USER = process.env.VUE_APP_API_URL_PLACES_USER

export const placeService = {
    async getItems(id) {
        try {
            return await Vue.axios.get(PLACES + id).then((response) => response.data)
        } catch (error) {
            return Promise.reject(error)
        }
    },
    async getPmsItems() {
        try {
            return await Vue.axios.get(PLACES_PMS).then((response) => response.data)
        } catch (error) {
            return Promise.reject(error)
        }
    },
    async getUserItems() {
        try {
            return await Vue.axios.get(PLACES_USER.slice(0, -1)).then((response) => response.data)
        } catch (error) {
            return Promise.reject(error)
        }
    },
    async newUserItem(text) {
        try {
            return await Vue.axios
                .post(PLACES_USER.slice(0, -1), {
                    text: text,
                })
                .then((response) => response.data)
        } catch (error) {
            return Promise.reject(error)
        }
    },
    async delUserItem(id) {
        try {
            return await Vue.axios.delete(PLACES_USER + id).then((response) => response.data)
        } catch (error) {
            return Promise.reject(error)
        }
    },
}
